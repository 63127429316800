<template>
    <div>
        <st-filters-pagination
            stateModule="complain/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="header"
            :moreFilters="false"
        >
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ComplainModel } from '@/modules/complain/complain-model';
    import { ComplainPermissions } from '@/modules/complain/complain-permissions';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

    const { fields } = ComplainModel;

    const filterSchema = new FilterSchema([
        fields.reporting_user_full_name,
        fields.contact_user_full_name,
        fields.locality_name,
        fields.status,
        fields.created_date,
    ]);
    export default {
        name: 'ComplainListFilter',
        data() {
            return {
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
                total: 'complain/list/total',
                currentUser: 'auth/currentUser',
            }),
            hasPermissionToReadStaff() {
                return new ComplainPermissions(this.currentUser).readStaff;
            },
            hasPermissionToReadApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            //  Clarifications needed from Mgmt
            filters() {
                if (!this.hasPermissionToReadApplications) {
                    return filterSchema.fields.filter(filter => filter.key !== 'locality_name');
                }
                return filterSchema.fields;
            },
            header() {
                if (this.hasPermissionToReadStaff) {
                    return this.$t('COMPLAIN.STAFF_LIST.TABLE_HEADER');
                }
                return this.$t('COMPLAIN.LIST.TABLE_HEADER');
            },
        },
        methods: {
            ...mapActions({
                doFetch: 'complain/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                const payload = {
                  loadStaff: this.hasPermissionToReadStaff,
                  params,
                };
                this.doFetch(payload);
            },
        },

    };
</script>

<style scoped>

</style>
